
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ProjectShellTopBar from '@/components/views/NewProjectView/Overview/ProjectShellTopBar.vue';
import { Icon, Spin } from 'ant-design-vue';

@Component({
    name: 'ProjectShellComponent',
    components: { ProjectShellTopBar, Spin, Icon },
})
export default class ProjectShellComponent extends Vue {
    @Prop({ default: null }) private editAction!: () => Promise<void> | null;
    @Prop({ default: null }) private saveAction!: () => Promise<void> | null;
    @Prop({ default: null }) private viewAction!: () => Promise<void> | null;
    @Prop({ default: '' }) private title!: string;
    @Prop({ default: false }) private isExpandable!: boolean;
    @Prop({ default: false }) private disabledActions!: boolean;
    @Prop({ required: true }) private lockEditing!: boolean;
    @Prop({ default: false }) private editState!: boolean;
    @Prop({ default: false }) private loadingState!: boolean;

    private isEditMode = false;
    private isLoading = false;
    private isExpandedMode = false;

    private toggleLoading() {
        this.isLoading = !this.isLoading;
    }

    @Watch('editState')
    private toggleEdit(val: boolean) {
        this.isEditMode = val;
    }

    @Watch('loadingState')
    private toggleLoad(val: boolean) {
        this.isLoading = val;
    }
}
