
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Button, Icon, Tooltip } from 'ant-design-vue';
import { showErrorNotifications } from '@/helpers/NotificationHelper';
import { AxiosError } from 'axios';

@Component({
    name: 'ProjectShellTopBar',
    components: { Icon, Button, Tooltip },
})
export default class ProjectShellTopBar extends Vue {
    @Prop({ required: true }) private isEditMode!: boolean;
    @Prop({ required: true }) private isExpandedMode!: boolean;
    @Prop({ default: false }) private isExpandable!: boolean;
    @Prop({ required: true }) private isLoading!: boolean;
    @Prop({ required: true }) private disabledActions!: boolean;
    @Prop({ default: null }) private editAction!: () => Promise<void> | null;
    @Prop({ default: null }) private saveAction!: () => Promise<void> | null;
    @Prop({ default: null }) private viewAction!: () => Promise<void> | null;
    @Prop({ default: '' }) private title!: string;
    @Prop({ required: true }) private lockEditing!: boolean;

    private async onEdit() {
        this.$emit('update:isEditMode', true);
        if (this.editAction) {
            await this.editAction();
        }
    }

    private async onSave() {
        this.$emit('update:isLoading', true);

        if (this.saveAction) {
            try {
                await this.saveAction();
            } catch (e) {
                showErrorNotifications(e as AxiosError<any>);
                return Promise.reject(e);
            } finally {
                this.$emit('update:isLoading', false);
            }
        }

        this.$emit('update:isEditMode', false);

        this.$notification.success({
            message: this.$t('Promjene uspješne!'),
            description: '',
        });

        return Promise.resolve();
    }

    private onToggleExpandedMode() {
        this.$emit('update:isExpandedMode', !this.isExpandedMode);
    }

    private onCancel() {
        this.$emit('update:isEditMode', false);
    }

    private toggleLoading() {
        this.$emit('update:isLoading', !this.isLoading);
    }
}
